import colors from 'vuetify/lib/util/colors'
import { IconFontawesome } from '@freshsolutions/fresh-vue'

export default {
  /**
   * general icons
   */
  yes: {
    component: IconFontawesome,
    props: {
      icon: 'check',
      color: 'success'
    }
  },
  no: {
    component: IconFontawesome,
    props: {
      icon: 'xmark',
      color: 'error'
    }
  },
  notes: {
    component: IconFontawesome,
    props: {
      icon: 'clipboard'
    }
  },
  included: {
    component: IconFontawesome,
    props: {
      icon: 'circle-check',
      color: 'success'
    }
  },
  'not-included': {
    component: IconFontawesome,
    props: {
      icon: 'circle-xmark',
      color: 'error'
    }
  },
  options: {
    component: IconFontawesome,
    props: {
      icon: 'ellipsis-vertical'
    }
  },
  'external-link': {
    component: IconFontawesome,
    props: {
      icon: 'up-right-from-square'
    }
  },
  filter: {
    component: IconFontawesome,
    props: {
      icon: 'filter'
    }
  },
  rangliste: {
    component: IconFontawesome,
    props: {
      icon: 'list-ol'
    }
  },
  vorgaenge: {
    component: IconFontawesome,
    props: {
      icon: 'list-check'
    }
  },
  antrag: {
    component: IconFontawesome,
    props: {
      icon: 'file-signature'
    }
  },
  vorgang: {
    component: IconFontawesome,
    props: {
      icon: 'file-signature'
    }
  },
  vertrag: {
    component: IconFontawesome,
    props: {
      icon: 'file-signature'
    }
  },
  selfdisclosure: {
    component: IconFontawesome,
    props: {
      icon: 'id-card'
    }
  },
  leadfeedback: {
    component: IconFontawesome,
    props: {
      icon: 'star'
    }
  },
  claimstatement: {
    component: IconFontawesome,
    props: {
      icon: 'comment'
    }
  },
  versionen: {
    component: IconFontawesome,
    props: {
      icon: ['far', 'copy']
    }
  },
  user: {
    component: IconFontawesome,
    props: {
      icon: 'circle-user'
    }
  },
  users: {
    component: IconFontawesome,
    props: {
      icon: 'users'
    }
  },
  contact: {
    component: IconFontawesome,
    props: {
      icon: 'user'
    }
  },
  contacts: {
    component: IconFontawesome,
    props: {
      icon: 'users'
    }
  },
  company: {
    component: IconFontawesome,
    props: {
      icon: 'building'
    }
  },
  branch: {
    component: IconFontawesome,
    props: {
      icon: 'briefcase'
    }
  },
  'driver-license-number': {
    component: IconFontawesome,
    props: {
      icon: 'id-card'
    }
  },
  dashboard: {
    component: IconFontawesome,
    props: {
      icon: 'desktop'
    }
  },
  image: {
    component: IconFontawesome,
    props: {
      icon: 'image'
    }
  },
  images: {
    component: IconFontawesome,
    props: {
      icon: 'images'
    }
  },
  media: {
    component: IconFontawesome,
    props: {
      icon: 'photo-video'
    }
  },
  'damage-persons': {
    component: IconFontawesome,
    props: {
      icon: 'users'
    }
  },
  'damage-type': {
    component: IconFontawesome,
    props: {
      icon: 'shield-blank'
    }
  },
  'damage-police': {
    component: IconFontawesome,
    props: {
      icon: 'shield-blank'
    }
  },
  injured: {
    component: IconFontawesome,
    props: {
      icon: 'user-injured'
    }
  },
  brutto: {
    component: IconFontawesome,
    props: {
      icon: 'money-bill-1'
    }
  },
  netto: {
    component: IconFontawesome,
    props: {
      icon: 'euro-sign'
    }
  },
  mwst: {
    component: IconFontawesome,
    props: {
      icon: 'hand-holding-dollar'
    }
  },
  'car-inspection': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'car',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'check',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'car-wheels': {
    component: IconFontawesome,
    props: {
      icon: 'truck-monster'
    }
  },
  fuel: {
    component: IconFontawesome,
    props: {
      icon: 'gas-pump'
    }
  },
  qualitaetssiegel: {
    component: IconFontawesome,
    props: {
      icon: 'certificate'
    }
  },
  'fuel-tank': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'gas-pump',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'gauge',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'car-emissions': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'car',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'wind',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'car-plakette': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'car',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'spell-check',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'car-settings': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'car',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'gears',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'car-users': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'car',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'user',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'car-power': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'car',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'bolt',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'car-color': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'car',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'palette',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'user-vermittlerdaten': {
    component: IconFontawesome,
    props: {
      icon: 'user-tie'
    }
  },
  'user-carsharingcontactdata': {
    component: IconFontawesome,
    props: {
      icon: 'envelopes-bulk'
    }
  },
  'user-carsharingcontractdata': {
    component: IconFontawesome,
    props: {
      icon: 'id-card'
    }
  },
  'carsharing-carsharinglessorcertificates': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'certificate',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'user-history': {
    component: IconFontawesome,
    props: {
      icon: 'clock-rotate-left'
    }
  },
  'current-reservation': {
    component: IconFontawesome,
    props: {
      icon: 'tag'
    }
  },
  'old-reservation': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'tag',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'xmark',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  password: {
    component: IconFontawesome,
    props: {
      icon: 'key'
    }
  },
  schadenmeldung: {
    component: IconFontawesome,
    props: {
      icon: 'car-crash'
    }
  },
  provision: {
    component: IconFontawesome,
    props: {
      icon: 'money-bill-wave'
    }
  },
  filecenter: {
    component: IconFontawesome,
    props: {
      icon: 'folder'
    }
  },
  'filecenter-group': {
    component: IconFontawesome,
    props: {
      icon: 'layer-group'
    }
  },
  quicklinks: {
    component: IconFontawesome,
    props: {
      icon: 'link'
    }
  },
  marketing: {
    component: IconFontawesome,
    props: {
      icon: 'bullhorn'
    }
  },
  tasks: {
    component: IconFontawesome,
    props: {
      icon: 'list-check'
    }
  },
  statistics: {
    component: IconFontawesome,
    props: {
      icon: 'chart-line'
    }
  },
  bestand: {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'users',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'database',
          transform: 'shrink-8 down-6 right-6'
        }
      ]
    }
  },
  'bestand-partner': {
    component: IconFontawesome,
    props: {
      icon: 'users'
    }
  },
  'bestand-vertraege': {
    component: IconFontawesome,
    props: {
      icon: 'file-signature'
    }
  },
  'bestand-schaeden': {
    component: IconFontawesome,
    props: {
      icon: 'car-crash'
    }
  },
  settings: {
    component: IconFontawesome,
    props: {
      icon: 'gear'
    }
  },
  system: {
    component: IconFontawesome,
    props: {
      icon: 'cubes'
    }
  },
  controlling: {
    component: IconFontawesome,
    props: {
      icon: 'chart-pie'
    }
  },
  tools: {
    component: IconFontawesome,
    props: {
      icon: 'screwdriver'
    }
  },
  supportcenter: {
    component: IconFontawesome,
    props: {
      icon: 'envelope-open-text'
    }
  },
  'supportcenter-fab': {
    component: IconFontawesome,
    props: {
      icon: 'question'
    }
  },
  schaden: {
    component: IconFontawesome,
    props: {
      icon: 'car-crash'
    }
  },
  permissions: {
    component: IconFontawesome,
    props: {
      icon: 'lock'
    }
  },
  permission: {
    component: IconFontawesome,
    props: {
      icon: 'key'
    }
  },
  'permission-connect': {
    component: IconFontawesome,
    props: {
      icon: 'circle-plus',
      color: 'success'
    }
  },
  'permission-disconnect': {
    component: IconFontawesome,
    props: {
      icon: 'circle-minus',
      color: 'error'
    }
  },
  role: {
    component: IconFontawesome,
    props: {
      icon: 'users'
    }
  },
  vertriebspartner: {
    component: IconFontawesome,
    props: {
      icon: 'handshake'
    }
  },
  management: {
    component: IconFontawesome,
    props: {
      icon: 'tools'
    }
  },
  banken: {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'bank',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'money-bill',
          transform: 'shrink-8 down-6 right-6'
        }
      ]
    }
  },
  employer: {
    component: IconFontawesome,
    props: {
      icon: 'building'
    }
  },
  employee: {
    component: IconFontawesome,
    props: {
      icon: 'user'
    }
  },
  employees: {
    component: IconFontawesome,
    props: {
      icon: 'users'
    }
  },
  calendar: {
    component: IconFontawesome,
    props: {
      icon: 'calendar-check'
    }
  },
  'personalmarkt-online': {
    component: IconFontawesome,
    props: {
      icon: 'graduation-cap'
    }
  },
  customer: {
    component: IconFontawesome,
    props: {
      icon: 'user'
    }
  },
  customers: {
    component: IconFontawesome,
    props: {
      icon: 'users'
    }
  },
  insurance: {
    component: IconFontawesome,
    props: {
      icon: 'shield-halved'
    }
  },
  'insurance-object': {
    component: IconFontawesome,
    props: {
      icon: 'database'
    }
  },
  systempartner: {
    component: IconFontawesome,
    props: {
      icon: 'users-cog'
    }
  },
  fahrzeugmarkt: {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'car',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'euro-sign',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  contract: {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-signature',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'euro-sign',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'contract-sale': {
    component: IconFontawesome,
    props: {
      icon: 'euro-sign'
    }
  },
  'contract-purchase': {
    component: IconFontawesome,
    props: {
      icon: 'shopping-cart'
    }
  },
  'contract-order': {
    component: IconFontawesome,
    props: {
      icon: 'clipboard-check'
    }
  },
  'contract-rental': {
    component: IconFontawesome,
    props: {
      icon: 'car-on'
    }
  },
  'bill-number': {
    component: IconFontawesome,
    props: {
      icon: 'file-invoice'
    }
  },
  'vehicle-valuations': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'car',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'euro-sign',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  fahrzeugangebote: {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'car',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  lead: {
    component: IconFontawesome,
    props: {
      icon: 'file-signature'
    }
  },
  leads: {
    component: IconFontawesome,
    props: {
      icon: 'file-signature'
    }
  },
  fahrzeuge: {
    component: IconFontawesome,
    props: {
      icon: 'list-check'
    }
  },
  fahrzeug: {
    component: IconFontawesome,
    props: {
      icon: 'car'
    }
  },
  fahrzeugtyp: {
    component: IconFontawesome,
    props: {
      icon: 'car'
    }
  },
  fahrzeugmarke: {
    component: IconFontawesome,
    props: {
      icon: 'car'
    }
  },
  fahrzeugreifen: {
    component: IconFontawesome,
    props: {
      icon: 'cookie'
    }
  },
  fahrzeugmodell: {
    component: IconFontawesome,
    props: {
      icon: 'car'
    }
  },
  laufzeit: {
    component: IconFontawesome,
    props: {
      icon: 'clock'
    }
  },
  birthday: {
    component: IconFontawesome,
    props: {
      icon: 'birthday-cake'
    }
  },
  percent: {
    component: IconFontawesome,
    props: {
      icon: 'percent'
    }
  },
  'marital-status': {
    component: IconFontawesome,
    props: {
      icon: 'ring'
    }
  },
  iban: {
    component: IconFontawesome,
    props: {
      icon: 'money-check'
    }
  },
  zahlweise: {
    component: IconFontawesome,
    props: {
      icon: 'money-bill-wave'
    }
  },
  ratenzuschlag: {
    component: IconFontawesome,
    props: {
      icon: 'money-bill-1'
    }
  },
  allgemein: {
    component: IconFontawesome,
    props: {
      icon: 'tools'
    }
  },
  theme: {
    component: IconFontawesome,
    props: {
      icon: 'palette'
    }
  },
  finanzierungsart: {
    component: IconFontawesome,
    props: {
      icon: 'wallet'
    }
  },
  abstellplatz: {
    component: IconFontawesome,
    props: {
      icon: 'parking'
    }
  },
  'email-from': {
    component: IconFontawesome,
    props: {
      icon: 'id-card'
    }
  },
  recipients: {
    component: IconFontawesome,
    props: {
      icon: 'paper-plane'
    }
  },
  phone: {
    component: IconFontawesome,
    props: {
      icon: 'phone'
    }
  },
  mobile: {
    component: IconFontawesome,
    props: {
      icon: 'mobile'
    }
  },
  dataset: {
    component: IconFontawesome,
    props: {
      icon: 'database'
    }
  },
  search: {
    component: IconFontawesome,
    props: {
      icon: 'magnifying-glass'
    }
  },
  'search-partner': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'user',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'magnifying-glass',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  autohaus: {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'store',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'car',
          transform: 'shrink-6 down-6 right-8'
        }
      ]
    }
  },
  'autohaus-standort': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'store',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'car',
          transform: 'shrink-6 down-6 right-8'
        }
      ]
    }
  },
  produktpartner: {
    component: IconFontawesome,
    props: {
      icon: 'handshake'
    }
  },
  carsharing: {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'car',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'handshake',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  builder: {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'building',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'user-tie',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  verkaeufer: {
    component: IconFontawesome,
    props: {
      icon: 'user-tie'
    }
  },
  versicherungsnehmer: {
    component: IconFontawesome,
    props: {
      icon: 'user'
    }
  },
  policyholder: {
    component: IconFontawesome,
    props: {
      icon: 'user'
    }
  },
  land: {
    component: IconFontawesome,
    props: {
      icon: 'flag'
    }
  },
  wert: {
    component: IconFontawesome,
    props: {
      icon: 'coins'
    }
  },
  umsatz: {
    component: IconFontawesome,
    props: {
      icon: 'coins'
    }
  },
  kilometerstand: {
    component: IconFontawesome,
    props: {
      icon: 'gauge'
    }
  },
  reparaturkosten: {
    component: IconFontawesome,
    props: {
      icon: 'coins'
    }
  },
  gesamtgewicht: {
    component: IconFontawesome,
    props: {
      icon: 'weight-hanging'
    }
  },
  aufhebungsgrund: {
    component: IconFontawesome,
    props: {
      icon: 'circle-question'
    }
  },
  fahrleistung: {
    component: IconFontawesome,
    props: {
      icon: 'route'
    }
  },
  versicherung: {
    component: IconFontawesome,
    props: {
      icon: 'building'
    }
  },
  autohersteller: {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'building',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'car',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'versicherung-partnernummer': {
    component: IconFontawesome,
    props: {
      type: 'icon',
      icon: 'building'
    }
  },
  'versicherung-partner-laendercode': {
    component: IconFontawesome,
    props: {
      type: 'icon',
      icon: 'earth-europe'
    }
  },
  'versicherung-vertrag': {
    component: IconFontawesome,
    props: {
      icon: 'shield-blank'
    }
  },
  'versicherung-vertragsnummer': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'shield-blank',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'versicherung-versionsnummer': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'arrow-up-1-9',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'versicherung-produkt': {
    component: IconFontawesome,
    props: {
      icon: 'box-open'
    }
  },
  'versicherung-produktnummer': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'box-open',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'versicherung-produkttext': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'align-right',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'versicherung-laufzeit': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'clock-rotate-left',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'versicherung-hauptfaelligkeit': {
    component: IconFontawesome,
    props: {
      icon: 'calendar-days'
    }
  },
  'versicherung-zahlungsart': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'money-check-dollar',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'info',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'versicherung-zahlungsweise': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'money-check-dollar',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'clock',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'versicherung-beitrag': {
    component: IconFontawesome,
    props: {
      icon: 'euro-sign'
    }
  },
  'versicherung-schaden-datum': {
    component: IconFontawesome,
    props: {
      icon: 'calendar'
    }
  },
  'versicherung-schaden-status': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'car-crash',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'arrows-rotate',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'versicherung-schadenmeldung': {
    component: IconFontawesome,
    props: {
      icon: 'car-crash'
    }
  },
  'versicherung-schadennummer': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'car-crash',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'shield-blank',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'versicherung-schaden-version': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'car-crash',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'arrow-up-1-9',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'versicherung-schaden-infos': {
    component: IconFontawesome,
    props: {
      icon: 'circle-info'
    }
  },
  'versicherung-schaden-zahlungen': {
    component: IconFontawesome,
    props: {
      icon: 'money-bill-wave'
    }
  },
  'versicherung-schaden-statistik': {
    component: IconFontawesome,
    props: {
      icon: 'chart-line'
    }
  },
  vorversicherung: {
    component: IconFontawesome,
    props: {
      icon: 'building'
    }
  },
  'vorversicherung-sf-klasse': {
    component: IconFontawesome,
    props: {
      icon: 'car-crash'
    }
  },
  'vorversicherung-sf-stand': {
    component: IconFontawesome,
    props: {
      icon: 'car-crash'
    }
  },
  'vorversicherung-partnernummer': {
    component: IconFontawesome,
    props: {
      type: 'icon',
      icon: 'building'
    }
  },
  'vorversicherung-vertragsnummer': {
    component: IconFontawesome,
    props: {
      type: 'icon',
      icon: 'file-signature'
    }
  },
  adresse: {
    component: IconFontawesome,
    props: {
      icon: 'location-pin'
    }
  },
  salutation: {
    component: IconFontawesome,
    props: {
      icon: 'id-card-clip'
    }
  },
  name: {
    component: IconFontawesome,
    props: {
      icon: 'id-card'
    }
  },
  vorname: {
    component: IconFontawesome,
    props: {
      icon: 'id-card'
    }
  },
  nachname: {
    component: IconFontawesome,
    props: {
      icon: 'id-card'
    }
  },
  firmenname: {
    component: IconFontawesome,
    props: {
      icon: 'id-card'
    }
  },
  freitext: {
    component: IconFontawesome,
    props: {
      icon: 'envelope-open-text'
    }
  },
  message: {
    component: IconFontawesome,
    props: {
      icon: 'envelope-open-text'
    }
  },
  comment: {
    component: IconFontawesome,
    props: {
      icon: 'comment'
    }
  },
  email: {
    component: IconFontawesome,
    props: {
      icon: 'envelope'
    }
  },
  versicherungsbeginn: {
    component: IconFontawesome,
    props: {
      icon: 'calendar-check'
    }
  },
  versicherungsende: {
    component: IconFontawesome,
    props: {
      icon: 'calendar-xmark'
    }
  },
  versicherungsablauf: {
    component: IconFontawesome,
    props: {
      icon: 'calendar-xmark'
    }
  },
  'completion-date': {
    component: IconFontawesome,
    props: {
      icon: 'calendar-check'
    }
  },
  date: {
    component: IconFontawesome,
    props: {
      icon: 'calendar-day'
    }
  },
  'date-to': {
    component: IconFontawesome,
    props: {
      icon: 'arrow-right'
    }
  },
  time: {
    component: IconFontawesome,
    props: {
      icon: 'clock'
    }
  },
  fahrzeugart: {
    component: IconFontawesome,
    props: {
      icon: 'car'
    }
  },
  fahrzeugstatus: {
    component: IconFontawesome,
    props: {
      icon: 'circle-info'
    }
  },
  fahrzeugidentnummer: {
    component: IconFontawesome,
    props: {
      icon: 'indent'
    }
  },
  vertragsart: {
    component: IconFontawesome,
    props: {
      icon: 'copy'
    }
  },
  turnus: {
    component: IconFontawesome,
    props: {
      icon: 'clock-rotate-left'
    }
  },
  vin: {
    component: IconFontawesome,
    props: {
      icon: 'id-card'
    }
  },
  'vertragsart-angefragt': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'copy',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'user',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'vertragsart-ls': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'car',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'file-signature',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'vertragsart-fz': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'car',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'money-bill',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'vertragsart-vk': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'car',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'key',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'vertragsart-ab': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'car',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'clock',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  zielgruppe: {
    component: IconFontawesome,
    props: {
      icon: 'users'
    }
  },
  kennzeichen: {
    component: IconFontawesome,
    props: {
      icon: 'car'
    }
  },
  standtage: {
    component: IconFontawesome,
    props: {
      icon: 'parking'
    }
  },
  internal_id: {
    component: IconFontawesome,
    props: {
      icon: 'id-card'
    }
  },
  buchnummer: {
    component: IconFontawesome,
    props: {
      icon: 'book-open'
    }
  },
  vermittlernummer: {
    component: IconFontawesome,
    props: {
      icon: 'id-card'
    }
  },
  tippgebernummer: {
    component: IconFontawesome,
    props: {
      icon: 'id-card'
    }
  },
  evbnummer: {
    component: IconFontawesome,
    props: {
      icon: 'car'
    }
  },
  'evbnummer-halterschaft': {
    component: IconFontawesome,
    props: {
      icon: 'user'
    }
  },
  stueckpraemie: {
    component: IconFontawesome,
    props: {
      icon: 'money-bill-wave'
    }
  },
  stopploss: {
    component: IconFontawesome,
    props: {
      icon: 'chart-line'
    }
  },
  selbstbeteiligung: {
    component: IconFontawesome,
    props: {
      icon: 'user-shield'
    }
  },
  akb_stand: {
    component: IconFontawesome,
    props: {
      icon: 'file-contract'
    }
  },
  tb_stand: {
    component: IconFontawesome,
    props: {
      icon: 'file-contract'
    }
  },
  abrechnungsregelung: {
    component: IconFontawesome,
    props: {
      icon: 'money-bill-wave'
    }
  },
  zahlungsweise: {
    component: IconFontawesome,
    props: {
      icon: 'clock'
    }
  },
  subject: {
    component: IconFontawesome,
    props: {
      icon: 'pen'
    }
  },
  partner: {
    component: IconFontawesome,
    props: {
      icon: 'user'
    }
  },
  username: {
    component: IconFontawesome,
    props: {
      icon: 'user'
    }
  },
  konto: {
    component: IconFontawesome,
    props: {
      icon: 'wallet'
    }
  },
  driver: {
    component: IconFontawesome,
    props: {
      icon: 'user'
    }
  },
  kasko: {
    component: IconFontawesome,
    props: {
      icon: 'umbrella'
    }
  },
  vermittlerart: {
    component: IconFontawesome,
    props: {
      icon: 'user-tie'
    }
  },
  oeffentlicher_dienst: {
    component: IconFontawesome,
    props: {
      icon: 'briefcase'
    }
  },
  geschaeftsfuehrung: {
    component: IconFontawesome,
    props: {
      icon: 'user-tie'
    }
  },
  lastlogin: {
    component: IconFontawesome,
    props: {
      icon: 'clock'
    }
  },
  userid: {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'link',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'user',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  timestamps: {
    component: IconFontawesome,
    props: {
      icon: 'calendar-day'
    }
  },
  userstamps: {
    component: IconFontawesome,
    props: {
      icon: 'user'
    }
  },
  created_at: {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'calendar-day',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'plus',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  created_by: {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'user',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'plus',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  updated_at: {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'calendar-day',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'pen',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  updated_by: {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'user',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'pen',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  history: {
    component: IconFontawesome,
    props: {
      icon: 'clock-rotate-left'
    }
  },
  valid_from: {
    component: IconFontawesome,
    props: {
      icon: 'calendar'
    }
  },
  valid_to: {
    component: IconFontawesome,
    props: {
      icon: 'calendar'
    }
  },
  leadquelle: {
    component: IconFontawesome,
    props: {
      icon: 'right-to-bracket'
    }
  },
  fahrzeugangebot: {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'car',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  color: {
    component: IconFontawesome,
    props: {
      icon: 'palette'
    }
  },
  emailing: {
    component: IconFontawesome,
    props: {
      icon: 'mail-bulk'
    }
  },
  link: {
    component: IconFontawesome,
    props: {
      icon: 'link'
    }
  },
  partnertag: {
    component: IconFontawesome,
    props: {
      icon: 'tag'
    }
  },
  'partnertags-tag': {
    component: IconFontawesome,
    props: {
      icon: 'tags'
    }
  },
  'partnertags-group': {
    component: IconFontawesome,
    props: {
      icon: 'layer-group'
    }
  },
  sparten: {
    component: IconFontawesome,
    props: {
      icon: 'clipboard-check'
    }
  },
  action: {
    component: IconFontawesome,
    props: {
      icon: 'gears'
    }
  },
  duration: {
    component: IconFontawesome,
    props: {
      icon: 'clock'
    }
  },
  arrowup: {
    component: IconFontawesome,
    props: {
      icon: 'chevron-up'
    }
  },
  arrowdown: {
    component: IconFontawesome,
    props: {
      icon: 'chevron-down'
    }
  },
  'construction-cost': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'hard-hat',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'euro-sign',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'construction-material-cost': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'hammer',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'euro-sign',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  reminder: {
    component: IconFontawesome,
    props: {
      icon: 'bell'
    }
  },
  duplicate: {
    component: IconFontawesome,
    props: {
      icon: 'people-arrows-left-right'
    }
  },
  homepage: {
    component: IconFontawesome,
    props: {
      icon: 'globe'
    }
  },
  'warning-isshown': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'eye',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'triangle-exclamation',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'warning-duplicates': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'people-arrows-left-right',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'triangle-exclamation',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'warning-fahrzeugangebotpricechanges': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'clock',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'triangle-exclamation',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  textmodules: {
    component: IconFontawesome,
    props: {
      icon: 'envelope-open-text'
    }
  },
  openingHours: {
    component: IconFontawesome,
    props: {
      icon: 'clock'
    }
  },
  location: {
    component: IconFontawesome,
    props: {
      icon: 'location-pin'
    }
  },
  'location-map': {
    component: IconFontawesome,
    props: {
      icon: 'location-dot'
    }
  },
  'tracking-modul': {
    component: IconFontawesome,
    props: {
      icon: 'plug-circle-check'
    }
  },
  meeting: {
    component: IconFontawesome,
    props: {
      icon: 'calendar-day'
    }
  },
  deleted: {
    component: IconFontawesome,
    props: {
      icon: 'trash'
    }
  },
  sale: {
    component: IconFontawesome,
    props: {
      icon: 'wallet'
    }
  },
  features: {
    component: IconFontawesome,
    props: {
      icon: 'list-check'
    }
  },
  description: {
    component: IconFontawesome,
    props: {
      icon: 'sticky-note'
    }
  },
  fahrzeugevents: {
    component: IconFontawesome,
    props: {
      icon: 'calendar-day'
    }
  },
  'event-type': {
    component: IconFontawesome,
    props: {
      icon: 'calendar-check'
    }
  },
  note: {
    component: IconFontawesome,
    props: {
      icon: 'sticky-note'
    }
  },
  'arrow-left': {
    component: IconFontawesome,
    props: {
      icon: 'arrow-left'
    }
  },
  'arrow-right': {
    component: IconFontawesome,
    props: {
      icon: 'arrow-right'
    }
  },
  tax: {
    component: IconFontawesome,
    props: {
      icon: 'hand-holding-dollar'
    }
  },
  'phonecall-reached': {
    component: IconFontawesome,
    props: {
      icon: 'question'
    }
  },
  favorite: {
    component: IconFontawesome,
    props: {
      icon: 'star'
    }
  },
  visible: {
    component: IconFontawesome,
    props: {
      icon: 'eye'
    }
  },
  hidden: {
    component: IconFontawesome,
    props: {
      icon: 'eye-slash'
    }
  },
  'privacy-settings': {
    component: IconFontawesome,
    props: {
      icon: 'shield'
    }
  },
  debug: {
    component: IconFontawesome,
    props: {
      icon: 'bug'
    }
  },

  // Views
  'view-table': {
    component: IconFontawesome,
    props: {
      icon: 'list'
    }
  },
  'view-chart': {
    component: IconFontawesome,
    props: {
      icon: 'chart-line'
    }
  },
  'view-timeline': {
    component: IconFontawesome,
    props: {
      icon: 'bars-staggered'
    }
  },
  'view-expansion-panel': {
    component: IconFontawesome,
    props: {
      icon: 'rectangle-list'
    }
  },

  // Orders
  'order-desc': {
    component: IconFontawesome,
    props: {
      icon: 'arrow-down-9-1'
    }
  },
  'order-asc': {
    component: IconFontawesome,
    props: {
      icon: 'arrow-down-1-9'
    }
  },

  // Partnerrollen
  'partnerrolle-PRDPAR_BANK': {
    component: IconFontawesome,
    props: {
      icon: 'bank'
    }
  },
  'partnerrolle-PRDPAR_IMMO': {
    component: IconFontawesome,
    props: {
      icon: 'house'
    }
  },
  'partnerrolle-PRDPAR_VER': {
    component: IconFontawesome,
    props: {
      icon: 'umbrella'
    }
  },
  'partnerrolle-PRDPAR_SONSTIGE': {
    component: IconFontawesome,
    props: {
      icon: 'question'
    }
  },

  // Roles
  'roles-null': {
    component: IconFontawesome,
    props: {
      icon: 'ban',
      color: 'error'
    }
  },

  'roles-freshcarflat-user': {
    component: IconFontawesome,
    props: {
      icon: 'user'
    }
  },
  'roles-freshcarflat-manager': {
    component: IconFontawesome,
    props: {
      icon: 'crown'
    }
  },

  'roles-builder-office': {
    component: IconFontawesome,
    props: {
      icon: 'user'
    }
  },
  'roles-builder-property-management': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'house',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'user',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },

  'roles-fahrzeugmarkt-standortuser': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'store',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'user',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'roles-fahrzeugmarkt-user': {
    component: IconFontawesome,
    props: {
      icon: 'user'
    }
  },
  'roles-fahrzeugmarkt-standortmanager': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'store',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'crown',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'roles-fahrzeugmarkt-manager': {
    component: IconFontawesome,
    props: {
      icon: 'crown'
    }
  },

  // Documents
  documents: {
    component: IconFontawesome,
    props: {
      icon: 'file-contract'
    }
  },
  'documents-status-AVAILABLE': {
    component: IconFontawesome,
    props: {
      icon: 'circle-check',
      color: 'success'
    }
  },
  'documents-status-NOT_AVAILABLE': {
    component: IconFontawesome,
    props: {
      icon: 'circle-xmark',
      color: 'error'
    }
  },

  // User status
  'user-status-ONLINE': {
    component: IconFontawesome,
    props: {
      icon: 'circle-check',
      color: 'success'
    }
  },
  'user-status-OFFLINE': {
    component: IconFontawesome,
    props: {
      icon: 'circle-xmark',
      color: 'error'
    }
  },
  'user-status-BUSY': {
    component: IconFontawesome,
    props: {
      icon: 'clock',
      color: 'warning'
    }
  },
  'user-status-DEFAULT': {
    component: IconFontawesome,
    props: {
      icon: 'circle-question'
    }
  },

  // Kommunikationsmedien
  'kommunikationsmedium-EMAILP': {
    component: IconFontawesome,
    props: {
      icon: 'envelope'
    }
  },
  'kommunikationsmedium-EMAILG': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'building',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'envelope',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'kommunikationsmedium-TELP': {
    component: IconFontawesome,
    props: {
      icon: 'phone'
    }
  },
  'kommunikationsmedium-TELM': {
    component: IconFontawesome,
    props: {
      icon: 'mobile'
    }
  },
  'kommunikationsmedium-TELMP': {
    component: IconFontawesome,
    props: {
      icon: 'mobile'
    }
  },
  'kommunikationsmedium-TELG': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'building',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'phone',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'kommunikationsmedium-TELMG': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'building',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'mobile',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'kommunikationsmedium-FAX': {
    component: IconFontawesome,
    props: {
      icon: 'fax'
    }
  },
  'kommunikationsmedium-FAXP': {
    component: IconFontawesome,
    props: {
      icon: 'fax'
    }
  },
  'kommunikationsmedium-FAXG': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'building',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'fax',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'kommunikationsmedium-HOTLINE': {
    component: IconFontawesome,
    props: {
      icon: 'phone'
    }
  },

  // Online-Schadenmeldung
  'damage-status-DEFAULT': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: colors.grey.lighten1,
      iconChildren: [
        {
          icon: 'question',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },
  'damage-status-NEW': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'error',
      iconChildren: [
        {
          icon: 'pen',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },
  'damage-status-SENT': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'orange',
      iconChildren: [
        {
          icon: 'angle-double-right',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },
  'damage-status-IN_PROGRESS': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'warning',
      iconChildren: [
        {
          icon: 'clock',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },
  'damage-status-CLOSED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'success',
      iconChildren: [
        {
          icon: 'check',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },

  // Sicherungsscheine
  'lessor-certificate-status-DEFAULT': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: colors.grey.lighten1,
      iconChildren: [
        {
          icon: 'question',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },
  'lessor-certificate-status-SENT': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'orange',
      iconChildren: [
        {
          icon: 'angle-double-right',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },
  'lessor-certificate-status-CLOSED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'square',
      color: 'success',
      iconChildren: [
        {
          icon: 'check',
          transform: 'shrink-7',
          color: 'white'
        }
      ]
    }
  },

  // Customers
  'customer-icon-status': {
    component: IconFontawesome,
    props: {
      icon: 'circle-question'
    }
  },
  'customer-status-PREPARATION': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      color: 'grey',
      iconChildren: [
        {
          icon: 'user-tie',
          transform: 'shrink-6 down-0 right-0',
          color: 'white'
        }
      ]
    }
  },
  'customer-status-PROGRESS': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      color: 'warning',
      iconChildren: [
        {
          icon: 'pen',
          transform: 'shrink-6 down-0 right-0',
          color: 'white'
        }
      ]
    }
  },
  'customer-status-ACTIVE': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      color: 'success',
      iconChildren: [
        {
          icon: 'check',
          transform: 'shrink-6 down-0 right-0',
          color: 'white'
        }
      ]
    }
  },
  'customer-status-DELETIONFLAG': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      color: 'error',
      iconChildren: [
        {
          icon: 'trash-can',
          transform: 'shrink-6 down-0 right-0',
          color: 'white'
        }
      ]
    }
  },
  'customer-status-DEFAULT': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      color: colors.grey.lighten1,
      iconChildren: [
        {
          icon: 'question',
          transform: 'shrink-6 down-0 right-0',
          color: 'white'
        }
      ]
    }
  },
  'customer-dsgvo': {
    component: IconFontawesome,
    props: {
      icon: 'ban'
    }
  },
  'create-dataprotection': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'lock',
          transform: 'shrink-8 down-8 right-8',
          color: 'accent'
        }
      ]
    }
  },
  'dataprotection-personal-data-pdf': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'database',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'file-pdf',
          transform: 'shrink-8 down-8 right-8',
          color: 'accent'
        }
      ]
    }
  },
  'dataprotection-personal-data-email': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'database',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'paper-plane',
          transform: 'shrink-8 down-8 right-8',
          color: 'accent'
        }
      ]
    }
  },

  // Fahrzeuge
  'fahrzeug-main-data': {
    component: IconFontawesome,
    props: {
      icon: 'folder'
    }
  },
  'fahrzeug-environment': {
    component: IconFontawesome,
    props: {
      icon: 'tree'
    }
  },
  'fahrzeug-performance': {
    component: IconFontawesome,
    props: {
      icon: 'eye'
    }
  },
  'fahrzeug-notes': {
    component: IconFontawesome,
    props: {
      icon: 'clipboard'
    }
  },
  'fahrzeug-precontractual': {
    component: IconFontawesome,
    props: {
      icon: 'file-signature'
    }
  },
  'fahrzeug-revenue': {
    component: IconFontawesome,
    props: {
      icon: 'money-bill-wave'
    }
  },
  'fahrzeug-icon-status': {
    component: IconFontawesome,
    props: {
      icon: 'circle-question'
    }
  },
  'fahrzeug-status-DEFAULT': {
    component: IconFontawesome,
    props: {
      icon: 'pencil-ruler',
      color: colors.grey.lighten1
    }
  },
  'fahrzeug-status-DEFAULT-online': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'circle-check',
      color: colors.grey.lighten1,
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'earth-europe',
          transform: 'shrink-6 down-6 right-6',
          color: 'success'
        }
      ]
    }
  },
  'fahrzeug-status-DEFAULT-online-planned': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'circle-check',
      color: colors.grey.lighten1,
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'clock',
          transform: 'shrink-6 down-6 right-6',
          color: 'info'
        }
      ]
    }
  },
  'fahrzeug-status-DEFAULT-offline': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'circle-check',
      color: colors.grey.lighten1,
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'circle-xmark',
          transform: 'shrink-6 down-6 right-6',
          color: 'error'
        }
      ]
    }
  },
  'fahrzeug-status-ORDER': {
    component: IconFontawesome,
    props: {
      icon: 'cart-shopping'
    }
  },
  'fahrzeug-status-PURCHASE': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'list',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'euro-sign',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'fahrzeug-status-PRE_STOCK': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'list',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'clock',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'fahrzeug-status-STOCK': {
    component: IconFontawesome,
    props: {
      icon: 'list'
    }
  },
  'fahrzeug-status-EXTERNAL': {
    component: IconFontawesome,
    props: {
      icon: 'arrow-up-right-from-square'
    }
  },
  'fahrzeug-status-SOLD': {
    component: IconFontawesome,
    props: {
      icon: 'euro-sign',
      color: colors.red.lighten1
    }
  },
  'fahrzeug-status-DEREGISTERED': {
    component: IconFontawesome,
    props: {
      icon: 'power-off',
      color: colors.red.lighten1
    }
  },
  'fahrzeug-status-SCRAPPED': {
    component: IconFontawesome,
    props: {
      icon: 'recycle',
      color: colors.red.lighten1
    }
  },

  // Portale und Social Media
  'socialmedia-icon-website': {
    component: IconFontawesome,
    props: {
      icon: 'house'
    }
  },
  'socialmedia-icon-mail': {
    component: IconFontawesome,
    props: {
      icon: 'at'
    }
  },
  'socialmedia-icon-whatsapp': {
    component: IconFontawesome,
    props: {
      icon: ['fab', 'whatsapp']
    }
  },
  'socialmedia-icon-facebook': {
    component: IconFontawesome,
    props: {
      icon: ['fab', 'facebook-square']
    }
  },
  'socialmedia-icon-instagram': {
    component: IconFontawesome,
    props: {
      icon: ['fab', 'instagram-square']
    }
  },
  'socialmedia-icon-twitter': {
    component: IconFontawesome,
    props: {
      icon: ['fab', 'twitter-square']
    }
  },
  'socialmedia-icon-linkedin': {
    component: IconFontawesome,
    props: {
      icon: ['fab', 'linkedin']
    }
  },
  'socialmedia-icon-xing': {
    component: IconFontawesome,
    props: {
      icon: ['fab', 'xing-square']
    }
  },

  // Fahrzeuginserate
  'fahrzeugangebot-icon-status': {
    component: IconFontawesome,
    props: {
      icon: 'car'
    }
  },
  'fahrzeugangebot-status-DRAFT': {
    component: IconFontawesome,
    props: {
      icon: 'pencil-ruler',
      color: colors.grey.lighten1
    }
  },
  'fahrzeugangebot-status-ONLINE': {
    component: IconFontawesome,
    props: {
      icon: 'earth-europe',
      color: 'success'
    }
  },
  'fahrzeugangebot-status-ONLINE_PLANNED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'earth-europe',
      color: 'success',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'clock',
          transform: 'shrink-6 down-6 right-6',
          color: 'info'
        }
      ]
    }
  },
  'fahrzeugangebot-status-OFFLINE': {
    component: IconFontawesome,
    props: {
      icon: 'circle-xmark',
      color: 'error'
    }
  },
  'fahrzeugangebot-status-EXPIRED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'circle-xmark',
      color: 'error',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'clock',
          transform: 'shrink-6 down-6 right-6',
          color: 'info'
        }
      ]
    }
  },
  'fahrzeugangebot-status-DEFAULT': {
    component: IconFontawesome,
    props: {
      icon: 'circle-question',
      color: 'warning'
    }
  },

  // Fahrzeugangebotcontract Status
  'fahrzeugangebotcontract-status-DEFAULT': {
    component: IconFontawesome,
    props: {
      icon: 'pencil-ruler'
    }
  },
  'fahrzeugangebotcontract-status-CLOSED': {
    component: IconFontawesome,
    props: {
      icon: 'check'
    }
  },
  'fahrzeugangebotcontract-status-CANCELED': {
    component: IconFontawesome,
    props: {
      icon: 'trash'
    }
  },

  // Fahrzeugpurchase Status
  'fahrzeugpurchase-status-DEFAULT': {
    component: IconFontawesome,
    props: {
      icon: 'pencil-ruler'
    }
  },
  'fahrzeugpurchase-status-CLOSED': {
    component: IconFontawesome,
    props: {
      icon: 'check'
    }
  },
  'fahrzeugpurchase-status-CANCELED': {
    component: IconFontawesome,
    props: {
      icon: 'trash'
    }
  },

  // Exposé, Preisschild, Energielabel, Flyer, Kosten/Erträge
  'create-expose': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-pdf',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'camera',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'create-preisschild': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-pdf',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'shopping-cart',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'create-energylabel': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-pdf',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'seedling',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'create-flyer': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-pdf',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'bookmark',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'create-rentalcontract': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-contract',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'car',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  'create-cardetails': {
    component: IconFontawesome,
    props: {
      icon: 'glasses'
    }
  },
  'create-costsrevenue': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file-pdf',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'money-bill',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },

  // Leads Status
  'lead-icon-status': {
    component: IconFontawesome,
    props: {
      icon: 'file-signature'
    }
  },
  'lead-status-NEW': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      color: 'error',
      iconChildren: [
        {
          icon: 'envelope',
          transform: 'shrink-6 down-0 right-0',
          color: 'white'
        }
      ]
    }
  },
  'lead-status-ASSIGNED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      color: 'warning',
      iconChildren: [
        {
          icon: 'user-tie',
          transform: 'shrink-6 down-0 right-0',
          color: 'white'
        }
      ]
    }
  },
  'lead-status-IN_PROGRESS': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      color: 'success',
      iconChildren: [
        {
          icon: 'pen',
          transform: 'shrink-6 down-0 right-0',
          color: 'white'
        }
      ]
    }
  },
  'lead-status-PAUSE': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      color: colors.grey.lighten1,
      iconChildren: [
        {
          icon: 'pause',
          transform: 'shrink-6 down-0 right-0',
          color: 'white'
        }
      ]
    }
  },
  'lead-status-CLOSED_DEAL': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      color: colors.grey.lighten1,
      iconChildren: [
        {
          icon: 'check',
          transform: 'shrink-6 down-0 right-0',
          color: 'white'
        }
      ]
    }
  },
  'lead-status-CLOSED_NO_DEAL': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      color: colors.grey.lighten1,
      iconChildren: [
        {
          icon: 'xmark',
          transform: 'shrink-6 down-0 right-0',
          color: 'white'
        }
      ]
    }
  },
  'lead-status-DEFAULT': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      color: colors.grey.lighten1,
      iconChildren: [
        {
          icon: 'question',
          transform: 'shrink-6 down-0 right-0',
          color: 'white'
        }
      ]
    }
  },

  // Leads Bearbeitungsstatus
  'lead-icon-bearbeitungsstatus': {
    component: IconFontawesome,
    props: {
      icon: 'car'
    }
  },
  'lead-bearbeitungsstatus-WAIT_CAR': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'car',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'clock',
          transform: 'shrink-6 down-8 right-8'
        }
      ]
    }
  },
  'lead-bearbeitungsstatus-CONTACT': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'user',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'check',
          transform: 'shrink-6 down-8 right-8'
        }
      ]
    }
  },
  'lead-bearbeitungsstatus-WAIT_CONTACT': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'user',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'clock',
          transform: 'shrink-6 down-8 right-8'
        }
      ]
    }
  },
  'lead-bearbeitungsstatus-WAIT_CUSTOMER': {
    component: IconFontawesome,
    props: {
      icon: 'user-slash'
    }
  },
  'lead-bearbeitungsstatus-OFFER': {
    component: IconFontawesome,
    props: {
      icon: 'file-contract'
    }
  },
  'lead-bearbeitungsstatus-DOCUMENTS_BANK': {
    component: IconFontawesome,
    props: {
      icon: 'file-invoice-dollar'
    }
  },
  'lead-bearbeitungsstatus-CONTACT_BANK': {
    component: IconFontawesome,
    props: {
      icon: 'bank'
    }
  },
  'lead-bearbeitungsstatus-CONTRACT': {
    component: IconFontawesome,
    props: {
      icon: 'file-signature'
    }
  },
  'lead-bearbeitungsstatus-NO_CONTRACT': {
    component: IconFontawesome,
    props: {
      icon: 'ban'
    }
  },
  'lead-bearbeitungsstatus-MEETING': {
    component: IconFontawesome,
    props: {
      icon: 'calendar-day'
    }
  },
  'lead-bearbeitungsstatus-DEFAULT': {
    component: IconFontawesome,
    props: {
      icon: 'circle-question'
    }
  },

  'lead-timeline-first-reaction': {
    component: IconFontawesome,
    props: {
      icon: 'arrow-pointer'
    }
  },
  'lead-timeline-today': {
    component: IconFontawesome,
    props: {
      icon: 'calendar-day'
    }
  },

  // Leads Lead Schließungsgrund
  'lead-icon-closed-reason': {
    component: IconFontawesome,
    props: {
      icon: 'file-signature'
    }
  },
  'lead-closed-reason-DEFAULT': {
    component: IconFontawesome,
    props: {
      icon: 'circle-question',
      color: 'warning'
    }
  },
  'lead-closed-reason-SOLD_CASH': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'car',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'key',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'lead-closed-reason-SOLD_LEASING': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'car',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'file-signature',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'lead-closed-reason-SOLD_FINANCING': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'car',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'money-bill',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'lead-closed-reason-SOLD_ABO': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'car',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'clock',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'lead-closed-reason-CUSTOMER_MEETING': {
    component: IconFontawesome,
    props: {
      icon: 'calendar-day'
    }
  },
  'lead-closed-reason-BAD_LEAD': {
    component: IconFontawesome,
    props: {
      icon: 'ban'
    }
  },
  'lead-closed-reason-DUPLICATE': {
    component: IconFontawesome,
    props: {
      icon: 'people-arrows-left-right'
    }
  },
  'lead-closed-reason-NO_CAR': {
    component: IconFontawesome,
    props: {
      icon: 'car'
    }
  },
  'lead-closed-reason-BAD_CREDIT': {
    component: IconFontawesome,
    props: {
      icon: 'euro-sign'
    }
  },
  'lead-closed-reason-DELIVERY_TIME': {
    component: IconFontawesome,
    props: {
      icon: 'clock-rotate-left'
    }
  },
  'lead-closed-reason-NO_RESPONSE': {
    component: IconFontawesome,
    props: {
      icon: 'user-clock'
    }
  },
  'lead-closed-reason-CUSTOMER_CANCEL': {
    component: IconFontawesome,
    props: {
      icon: 'user-slash'
    }
  },
  'lead-closed-reason-OTHERS': {
    component: IconFontawesome,
    props: {
      icon: 'file-signature'
    }
  },

  // Lead Type
  'lead-type-icon': {
    component: IconFontawesome,
    props: {
      icon: 'question'
    }
  },

  // Meeting Type
  'meeting-type-icon': {
    component: IconFontawesome,
    props: {
      icon: 'calendar-day'
    }
  },
  'meeting-type-DEFAULT': {
    component: IconFontawesome,
    props: {
      icon: 'circle-question',
      color: 'warning'
    }
  },
  'meeting-type-PERSONAL': {
    component: IconFontawesome,
    props: {
      icon: 'handshake'
    }
  },
  'meeting-type-ONLINE': {
    component: IconFontawesome,
    props: {
      icon: 'globe'
    }
  },
  'meeting-type-PHONE': {
    component: IconFontawesome,
    props: {
      icon: 'phone'
    }
  },
  'meeting-type-CALLBACK': {
    component: IconFontawesome,
    props: {
      icon: 'phone-volume'
    }
  },
  'meeting-type-LEASINGEXPIRE': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'car',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'arrow-left',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'meeting-type-TESTDRIVE': {
    component: IconFontawesome,
    props: {
      icon: 'car'
    }
  },
  'meeting-type-VEHICLE_DELIVERY': {
    component: IconFontawesome,
    props: {
      icon: 'truck-loading'
    }
  },
  'meeting-type-VEHICLE_PURCHASE': {
    component: IconFontawesome,
    props: {
      icon: 'shopping-cart'
    }
  },

  /**
   * WORKSHOP
   */
  workshop: {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'car',
      transform: 'down-8 right-8',
      iconChildren: [
        {
          icon: 'wrench',
          transform: 'shrink-8 down-8 right-8'
        }
      ]
    }
  },
  storage: {
    component: IconFontawesome,
    props: {
      icon: 'box-open'
    }
  },
  'storage-icon-status': {
    component: IconFontawesome,
    props: {
      icon: 'circle-question'
    }
  },
  'storage-status-OPEN': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      color: 'error',
      iconChildren: [
        {
          icon: 'folder-open',
          transform: 'shrink-6 down-0 right-0',
          color: 'white'
        }
      ]
    }
  },
  'storage-status-PROGRESS': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      color: 'warning',
      iconChildren: [
        {
          icon: 'pen',
          transform: 'shrink-6 down-0 right-0',
          color: 'white'
        }
      ]
    }
  },
  'storage-status-CLOSED': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      color: 'success',
      iconChildren: [
        {
          icon: 'check',
          transform: 'shrink-6 down-0 right-0',
          color: 'white'
        }
      ]
    }
  },
  'storage-status-DEFAULT': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      color: colors.grey.lighten1,
      iconChildren: [
        {
          icon: 'question',
          transform: 'shrink-6 down-0 right-0',
          color: 'white'
        }
      ]
    }
  },

  /**
   * external (BVGVUSER)
   */
  'external-statistics': {
    component: IconFontawesome,
    props: {
      icon: 'chart-pie'
    }
  },
  'external-objektsuche': {
    component: IconFontawesome,
    props: {
      icon: 'magnifying-glass'
    }
  },
  'external-vertragssuche': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'magnifying-glass',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'external-vertragsliste': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'file',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'list',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'external-schadensuche': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'car-crash',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'magnifying-glass',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'external-schadenliste': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'car-crash',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'list',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'external-schadenquote': {
    component: IconFontawesome,
    props: {
      type: 'layers',
      icon: 'circle',
      iconMask: 'car-crash',
      transform: 'down-6 right-6',
      iconChildren: [
        {
          icon: 'percent',
          transform: 'shrink-6 down-6 right-6'
        }
      ]
    }
  },
  'external-kontenabfrage': {
    component: IconFontawesome,
    props: {
      icon: 'money-bill-wave'
    }
  },
  'external-epostkorb': {
    component: IconFontawesome,
    props: {
      icon: 'envelope'
    }
  }
}
